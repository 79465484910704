import { Contracts } from '../types';
import * as avalanche from '@/assets/data/contracts/avalanche.json';

const contracts: Contracts = {
  distributionAssessor: '0x0000000000000000000000000000000000000000',
  securitizationPoolValueService: '0x0000000000000000000000000000000000000000',
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: '',
  multicall: '0xca11bde05977b3631167028862be2a173976ca11',
  authorizer: avalanche.Authorizer,
  vault: avalanche.Vault,
  weightedPoolFactory: avalanche.WeightedPoolFactory,
  stablePoolFactory: avalanche.ComposableStablePoolFactory,
  lidoRelayer: '',
  balancerHelpers: avalanche.BalancerHelpers,
  batchRelayer: avalanche.BalancerRelayer,
  gaugeFactory: avalanche.ChildChainLiquidityGaugeFactory,
  balancerMinter: avalanche.L2BalancerPseudoMinter,
  acceptedInvoiceToken: '0x0000000000000000000000000000000000000000',
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: avalanche.ChildChainGaugeRewardHelper,
};

export default contracts;
