import { Config } from '../types';
import keys from './keys';
import contracts from './contracts';
import pools from './pools';
import tokenlists from './tokenlists';
import tokens from './tokens';
import rateProviders from './rateProviders';

const config: Config = {
  key: '84532',
  chainId: 84532,
  chainName: 'Base Sepolia',
  name: 'Base Sepolia Testnet',
  shortName: 'Base Sepolia',
  monorepoName: 'base-sepolia',
  slug: 'base-sepolia',
  network: 'base-sepolia',
  trustWalletNetwork: 'base-sepolia',
  unknown: false,
  visibleInUI: true,
  testNetwork: true,
  rpc: `https://base-sepolia.blockpi.network/v1/rpc/public`,
  ws: `wss://base-sepolia-rpc.publicnode.com`,
  publicRpc: 'https://base-sepolia.blockpi.network/v1/rpc/public',
  explorer: 'https://sepolia.basescan.org/',
  explorerName: 'Base Sepolia Network Testnet Explorer',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-avalanche-v2',
  balancerApi: 'https://api.balancer.fi',
  poolsUrlV2: '',
  subgraphs: {
    main: [
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-avalanche-v2',
    ],
    aave: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2-avalanche',
    gauge:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-gauges-avalanche',
    blocks:
      'https://api.thegraph.com/subgraphs/name/iliaazhel/avalanche-blocks',
  },
  bridgeUrl: 'https://core.app/bridge/',
  supportsEIP1559: false,
  supportsElementPools: false,
  blockTime: 2,
  nativeAsset: {
    name: 'Sepolia Ether',
    address: '0x50cCa5ed8B4455fbe316785269FC82500b67fD48',
    symbol: 'ETH',
    decimals: 18,
    deeplinkId: 'ether',
    logoURI: 'tokens/eth.svg',
    minTransactionBuffer: '0.1',
  },
  vaultToken: {
    name: 'Vault',
    address: '0x0000000000000000000000000000000000000000',
    managerAddress: '0x0000000000000000000000000000000000000000',
    symbol: 'VAULT',
    decimals: 12,
    assetDecimals: 6,
    assetSymbol: 'USC',
    assetAddress: '0x0000000000000000000000000000000000000000',
    logoURI: 'tokens/celo.png',
    poolId: 493,
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'ethereum',
      platformId: 'sepolia',
    },
    apyVision: {
      networkName: 'avalanche',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 4,
    weight: 0,
  },
  tokenlists,
  rateProviders,
};

export default config;
